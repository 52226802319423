import React from "react";
import "./Services.css";
import img1 from "../../../assets/home/services/growth.png";
import img2 from "../../../assets/home/services/bingo.png";
import img3 from "../../../assets/home/services/data.png";
import img4 from "../../../assets/home/services/design.png";
import { Fade } from "react-reveal";

export default function Services() {
  return (
    <div className="services" id="services">
      <div className="container">
        <div className="row d-flex flex-column-reverse flex-lg-row justify-content-around align-items-center">
          <div className="col-lg-5 services-image"></div>
          <div className="col-lg-7 services-content d-flex flex-column justify-content-evenly">
            <h2>Explore Our Professional Business Solutions</h2>
            <div className="services-solutions">
              <div className="row">
                <Fade down>
                  <div className="col-lg-6 services-solution d-flex align-items-center">
                    <img
                      alt="wansenziroz"
                      src={img1}
                      className="services-solution-image"
                    />
                    <div className="services-solution-content">
                      <h3>Website Development</h3>
                      <p>
                        Custom websites designed for performance and user
                        experience.
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div className="col-lg-6 services-solution d-flex align-items-center">
                    <img
                      alt="wansenziroz"
                      src={img2}
                      className="services-solution-image"
                    />
                    <div className="services-solution-content">
                      <h3>Digital Marketing</h3>
                      <p>
                        Strategies that increase your online visibility and
                        growth.
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade left>
                  <div className="col-lg-6 services-solution d-flex align-items-center">
                    <img
                      alt="wansenziroz"
                      src={img3}
                      className="services-solution-image"
                    />
                    <div className="services-solution-content">
                      <h3>Custom/Machine Learning</h3>
                      <p>
                        Smart solutions that leverage data to drive business
                        insights.
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade up>
                  <div className="col-lg-6 services-solution d-flex align-items-center">
                    <img
                      alt="wansenziroz"
                      src={img4}
                      className="services-solution-image"
                    />
                    <div className="services-solution-content">
                      <h3>Mobile Development</h3>
                      <p>
                        Native and cross-platform apps to engage users on the
                        go.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
