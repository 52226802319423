import React from "react";
import { Fade, Zoom } from "react-reveal";
import "./Highlights.css";

export default function Highlights() {
  return (
    <div className="highlights" id="highlights">
      <div className="container">
        <h2>Checkout Our Featured Projects</h2>
        <p className="text-center">
          Here are three standout projects that showcase our expertise
        </p>
        <div className="project-higlights d-flex flex-wrap justify-content-center justify-content-lg-between">
          <Fade left>
            <div className="project-higlight">
              <div className="project-higlight-content">
                <div className="type">Web Development</div>
                <a
                  href="https://hairyblo-v3.vercel.app/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="name"
                >
                  Hairyblo
                </a>
              </div>
            </div>
          </Fade>
          <Zoom>
            <div className="project-higlight">
              <div className="project-higlight-content">
                <div className="type">App Development</div>
                <a
                  href="https://gokijany.electrixitaty.co/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="name"
                >
                  Gokijany
                </a>
              </div>
            </div>
          </Zoom>
          <Fade right>
            <div className="project-higlight">
              <div className="project-higlight-content">
                <div className="type">Machine Learning</div>
                <a
                  href="https://veezavyz.electrixitaty.co/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="name"
                >
                  Veezavyz
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
