import React from "react";
import { Fade } from "react-reveal";
import Swal from "sweetalert2";
import "./Pricing.css";

// Function to dynamically load Calendly script
const loadCalendlyScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    script.onload = resolve;
    document.body.appendChild(script);
  });
};

const showCalendlyPopup = async () => {
  // Load Calendly script dynamically
  await loadCalendlyScript();

  // SweetAlert feedback with custom backdrop
  Swal.fire({
    title: "Scheduling Appointment",
    text: "Redirecting you to the calendar...",
    icon: "info",
    showConfirmButton: false,
    timer: 2000, // Show for 2 seconds
  });

  // After SweetAlert, trigger Calendly popup
  setTimeout(() => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/ziroz", // Your Calendly link
      });
    } else {
      console.error("Calendly is not available.");
    }
  }, 2000); // Trigger Calendly after SweetAlert finishes
};

export default function Pricing() {
  return (
    <div className="pricing" id="pricing">
      <div className="container">
        <div className="w-100 w-lg-50 ">
          <h2>Our Flexible Pricing Plans</h2>
          <p className="w-50 w-lg-100">
            We understand that every business has unique needs and budgets.
            That’s why we offer flexible, subscription-based pricing plans
            designed to provide you with maximum value, ongoing support, and
            scalability without the heavy upfront costs.
          </p>
        </div>
        <div className="project-higlights d-flex flex-wrap justify-content-center align-items-center">
          <Fade down>
            <div className="project-higlight">
              <div className="pricing-header">
                <h5>Basic Plan</h5>
                <p>
                  Ideal for small businesses or startups that need a
                  professional online presence with essential features.
                </p>
                <h3>
                  <span>from</span> KES 13,000{" "}
                  <span>/ month for 3 months minimum</span>
                </h3>
              </div>
              <div className="pricing-breakdown">
                <ul>
                  <li>Website or app development</li>
                  <li>Basic maintenance</li>
                  <li>Customer support</li>
                </ul>
              </div>
              <button className="pricing-btn" onClick={showCalendlyPopup}>
                Get In Touch
              </button>
            </div>
          </Fade>
          <Fade up>
            <div className="project-higlight">
              <div className="pricing-header">
                <h5>Growth Plan</h5>
                <p>
                  Perfect for businesses ready to scale. Offers advanced
                  features and performance optimization.
                </p>
                <h3>
                  <span>from</span> KES 20,000{" "}
                  <span>/ month for 6 months minimum</span>
                </h3>
              </div>
              <div className="pricing-breakdown">
                <ul>
                  <li>Website/app development</li>
                  <li>Ongoing updates and enhancements</li>
                  <li>Strategic digital marketing insights</li>
                  <li>Conversion tracking</li>
                </ul>
              </div>
              <button className="pricing-btn" onClick={showCalendlyPopup}>
                Get In Touch
              </button>
            </div>
          </Fade>
          <Fade down>
            <div className="project-higlight">
              <div className="pricing-header">
                <h5>Enterprise Plan</h5>
                <p>
                  Tailored for larger businesses or nonprofits requiring
                  complex, custom solutions with full support.
                </p>
                <h3>
                  <span>from</span> KES 32,000{" "}
                  <span>/ month for 9 months minimum</span>
                </h3>
              </div>
              <div className="pricing-breakdown">
                <ul>
                  <li>
                    Comprehensive development (web, app, machine learning)
                  </li>
                  <li>Full maintenance, updates, and optimization</li>
                  <li>Detailed analytics and business insights</li>
                  <li>Advanced marketing and automation tools</li>
                </ul>
              </div>
              <button className="pricing-btn" onClick={showCalendlyPopup}>
                Get In Touch
              </button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
