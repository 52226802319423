import React, { useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert2
import "./Contact.css";
import { Bounce, Fade, Zoom } from "react-reveal";
import emailjs from "emailjs-com"; // Import EmailJS

export default function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsLoading(true); // Set loading state

    // Show loading alert
    Swal.fire({
      title: "Sending...",
      text: "Please wait while we send your message.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    // Send email
    emailjs
      .send(
        "service_trqami1",
        "template_o39nr3s",
        formData,
        "QStYaEDtI6KNWN4R2"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({
            // Reset form fields
            firstName: "",
            secondName: "",
            email: "",
            subject: "",
            message: "",
          });
          setIsLoading(false); // Reset loading state

          // Show success alert
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Message sent successfully!",
          });
        },
        (err) => {
          console.log("FAILED...", err);
          setIsLoading(false); // Reset loading state

          // Show error alert
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong while sending the message!",
          });
        }
      );
  };

  return (
    <div className="contact" id="contact">
      <div className="container">
        <h2>Talk To Us</h2>
        <p>
          We are ready to hear from you and respond within an hour, ask about
          anything
        </p>
        <form onSubmit={handleSubmit}>
          <div className="row d-flex justify-content-between align-items-center">
            <Fade left>
              <div className="form-group mt-4 col-lg-6">
                <label>First Name</label>
                <br />
                <input
                  className="w-100"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter your First Name"
                  required
                />
              </div>
            </Fade>
            <Fade right>
              <div className="form-group mt-4 col-lg-6">
                <label>Second Name</label>
                <br />
                <input
                  className="w-100"
                  type="text"
                  name="secondName"
                  value={formData.secondName}
                  onChange={handleChange}
                  placeholder="Enter your Second Name"
                  required
                />
              </div>
            </Fade>
          </div>
          <Zoom>
            <div className="form-group mt-4">
              <label>Your Email</label>
              <br />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
          </Zoom>
          <Fade right>
            <div className="form-group mt-4">
              <label>Subject</label>
              <br />
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject of your Message"
                required
              />
            </div>
          </Fade>
          <Bounce>
            <textarea
              className="mt-4 w-100"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Write your message"
              required
            ></textarea>
          </Bounce>

          <button type="submit" disabled={isLoading}>
            {isLoading ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
    </div>
  );
}
