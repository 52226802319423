import React from "react";
import "./Footer.css";
import logo from "../../assets/Wansenziroz_logo-no-bg.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="row footer-top d-flex justify-content-center justify-content-lg-between align-items-center ">
          <img src={logo} alt="wansenziroz" className="col-lg-2 footer-logo" />
          <div className="col-lg-4">
            <h4>Contacts</h4>
            <div className="row">
              <p className="col-md-7 d-flex">
                <MailOutlineIcon className="social-icon" />
                wansenziroz@electrixitaty.co
              </p>
              <p className="col-md-5 d-flex">
                <CallIcon className="social-icon" />
                +254705247958
              </p>
            </div>
          </div>
          <div className="col-lg-3 mb-4 mb-lg-0">
            <h4>Social</h4>
            <div className="d-flex row">
              <a
                className="col-3"
                href="https://www.instagram.com/wansenziroz/"
                rel="noreferrer"
                target={"_blank"}
              >
                <InstagramIcon className="social-icon" />
              </a>
              <a
                className="col-3"
                href="https://www.linkedin.com/company/wansenziroz"
                rel="noreferrer"
                target={"_blank"}
              >
                <LinkedInIcon className="social-icon" />
              </a>
              <a
                className="col-3"
                href="https://x.com/wansenziroz"
                rel="noreferrer"
                target={"_blank"}
              >
                <XIcon className="social-icon" />
              </a>
              <a
                className="col-3"
                href="https://www.facebook.com/profile.php?id=61551086575539"
                rel="noreferrer"
                target={"_blank"}
              >
                <FacebookIcon className="social-icon" />
              </a>
            </div>
          </div>
        </div>
        <p className="text-center">©2024 Wansenziroz, All Rights Reserved</p>
      </div>
    </div>
  );
}
