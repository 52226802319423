import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./home/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { useEffect, useState } from "react";
import Preloader from "./components/preloader/Preloader";

function App() {
  const [activeSection, setActiveSection] = useState("hero");
  useEffect(() => {
    const handleScroll = () => {
      // Find the section that is currently in the viewport
      const sections = [
        "hero",
        "services",
        "why-us",
        "highlights",
        "pricing",
        "marketing",
        "contact",
      ];
      for (const sectionId of sections) {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
            if (activeSection !== sectionId) {
              setActiveSection(sectionId);
            }
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  function ScrollToTopOnRouteChange() {
    const location = useLocation();
    useEffect(() => {
      if (location.pathname !== "/") {
        window.scrollTo(0, 0);
      }
    }, [location]);
    return null;
  }

  const [loading, setLoading] = useState(true); // State to manage the loading status

  useEffect(() => {
    // Simulate loading process
    setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds (simulate data fetching)
    }, 3000);
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        {loading ? (
          <Preloader />
        ) : (
          <>
            <Header activeSection={activeSection} />
            <ScrollToTopOnRouteChange />
            <Routes>
              <Route index path="/" element={<Home />} />
            </Routes>
            <Footer />
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
