import React from "react";
import "./Achievements.css";
import img1 from "../../../assets/home/achivement/project.png";
import img2 from "../../../assets/home/achivement/client.png";
import img3 from "../../../assets/home/achivement/team.png";
import { Fade, Zoom } from "react-reveal";

export default function Achievements() {
  return (
    <div className="achievements d-flex align-items-center">
      <div className="container">
        <div className="d-flex flex-column flex-lg-row justify-content-center row align-items-center">
          <Fade left>
            <div className="achievement col-lg-4">
              <img alt="wansenziroz achievements" src={img1} />
              <div className="content">
                <h3>150+</h3>
                <p>Project Completed</p>
              </div>
            </div>
          </Fade>

          <Zoom>
            <div className="achievement col-lg-4 ">
              <img alt="wansenziroz achievements" src={img2} />
              <div className="content">
                <h3>250+</h3>
                <p>Satisfied Clients</p>
              </div>
            </div>
          </Zoom>
          <Fade right>
            <div className="achievement col-lg-4">
              <img alt="wansenziroz achievements" src={img3} />
              <div className="content">
                <h3>50+</h3>
                <p>Expert Teams</p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
