import React from "react";
import Hero from "./sections/hero/Hero";
import Achievements from "./sections/achievements/Achievements";
import Highlights from "./sections/highlights/Highlights";
import Services from "./sections/services/Services";
import Pricing from "./sections/pricing/Pricing";
import WhyUs from "./sections/whyus/WhyUs";
import Contact from "./sections/contact/Contact";
// import Marketing from "./sections/marketing/Marketing";

export default function Home() {
  return (
    <div className="home">
      <Hero />
      <Achievements />
      <Services />
      <Highlights />
      <WhyUs />
      <Pricing />
      {/* <Marketing /> */}
      <Contact />
    </div>
  );
}
