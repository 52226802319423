import React from "react";
import "./Hero.css";
import { Fade } from "react-reveal";
import img from "../../../assets/wans-hero.png";

export default function Hero() {
  return (
    <div className="hero" id="hero">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-between">
          <Fade left>
            <div className="col-lg-7 col-xl-6 hero-content d-flex justify-content-center flex-column">
              <h1>Affordable Digital Solutions</h1>
              <p>
                For startups, SMEs, nonprofits, and professional. Our
                user-friendly websites, feature-rich mobile applications,
                advanced machine learning models, and tailored digital marketing
                strategies are designed to help you scale effortlessly
              </p>
              <p>
                We deploy, maintain, and continuously optimize your digital
                tools to help you convert site visits into loyal customers and
                drive sustainable business growth.
              </p>
            </div>
          </Fade>

          <img
            className="col-5 col-xl-6 hero-image"
            src={img}
            alt="wansenziroz software development"
          />
        </div>
      </div>
    </div>
  );
}
