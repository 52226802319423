import React from "react";
import { Fade } from "react-reveal";
import "./WhyUs.css";

export default function WhyUs() {
  return (
    <div className="why-us" id="why-us">
      <div className="container d-flex align-items-center">
        <div className="row d-flex align-items-center justify-content-around">
          <Fade left>
            <div className="col-lg-6 why-us-content d-flex justify-content-evenly flex-column">
              <h2>Your Growth Partner for Scalable Digital Solutions</h2>
              <p>
                At Wansenziroz, we do more than just develop and deliver. We are
                committed to your long-term success by providing tailored
                solutions that grow with your business. From ongoing maintenance
                to strategic insights that help turn traffic into clients, we
                focus on quality, innovation, and cost-effective support for
                startups, SMEs, nonprofits, and professionals.
              </p>
              <p>Let us help you scale and succeed!</p>
              <div className="why-us-reasons row">
                <div className="why-us-reason col-lg-3 d-flex align-items-center">
                  <div className="tiny-circle"></div>
                  Expert Team
                </div>
                <div className="why-us-reason col-lg-3 d-flex align-items-center">
                  <div className="tiny-circle"></div>
                  Tailored Solutions
                </div>
                <div className="why-us-reason col-lg-4 d-flex align-items-center">
                  <div className="tiny-circle"></div>
                  Agile Methodology
                </div>
                <div className="why-us-reason col-lg-4 d-flex align-items-center">
                  <div className="tiny-circle"></div>
                  Client-Centric Focus
                </div>
                <div className="why-us-reason col-lg-4 d-flex align-items-center">
                  <div className="tiny-circle"></div>
                  Proven Track Record
                </div>
              </div>
            </div>
          </Fade>

          <div className="col-lg-6 why-us-image"></div>
        </div>
      </div>
    </div>
  );
}
