import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import logo from "../../assets/Wansenziroz_logo-no-bg.png";

const sections = [
  "hero",
  "services",
  "highlights",
  "why-us",
  "pricing",
  // "marketing",
  "contact",
];

export default function Header({ activeSection }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const heroRef = useRef();
  const servicesRef = useRef();
  const highlightRef = useRef();
  const whyusRef = useRef();
  const pricingRef = useRef();
  // const marketingRef = useRef();
  const contactRef = useRef();

  const sectionRefs = {
    hero: heroRef,
    services: servicesRef,
    highlights: highlightRef,
    "why-us": whyusRef,
    pricing: pricingRef,
    // // marketing: marketingRef,
    contact: contactRef,
  };

  useEffect(() => {
    Object.keys(sectionRefs).forEach((key) => {
      if (key === activeSection) {
        sectionRefs[key].current.classList.add("active");
      } else {
        sectionRefs[key].current.classList.remove("active");
      }
    });
  }, [activeSection]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const dropDownMouseIn = (e) => e.currentTarget.classList.add("hovered");
  const dropDownMouseLeave = (e) => e.currentTarget.classList.remove("hovered");

  const scrollToSection = (id) => {
    setTimeout(() => {
      const target = document.getElementById(id);
      if (target) {
        const targetRect = target.getBoundingClientRect();
        const offset = 0.15 * window.innerHeight;
        const scrollPosition = window.pageYOffset + targetRect.top - offset;

        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  return (
    <div className="header d-flex justify-content-between align-items-center">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <a href={"/"}>
          <img alt="Lumona Mulengwa" src={logo} className="logo" />
        </a>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <span className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}></span>
          <span className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}></span>
          <span className={`icon-bar ${isMobileMenuOpen ? "open" : ""}`}></span>
        </div>
        <nav className={`nav-menu ${isMobileMenuOpen ? "open" : ""}`}>
          {sections.map((section) => (
            <div
              key={section}
              className="navs"
              onMouseEnter={dropDownMouseIn}
              onMouseLeave={dropDownMouseLeave}
            >
              <div
                onClick={() => scrollToSection(section)}
                className="nav-option"
                ref={sectionRefs[section]}
              >
                {section.charAt(0).toUpperCase() +
                  section.slice(1).replace("-", " ")}
              </div>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}
